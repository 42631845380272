<script lang="ts" setup generic="T">
import type { KeenSliderOptions } from "keen-slider";

const props = withDefaults(
	defineProps<{
		title: string;
		navigation?: boolean;
		list: T[];
		autoplay?: boolean;
		isComments?: boolean;
		spacing?: number;
		isSection?: boolean;
		offset?: number;
	}>(),
	{
		navigation: false,
		autoplay: false,
		loop: false,
		isComments: false,
		spacing: 8,
		isSection: false,
		offset: 0
	}
);
const getTitle = (title?: string) => {
	if (!title) {
		return "";
	}
	return title?.toLowerCase()?.replace(/ /g, "-");
};

const sliderActiveSlide = ref(0);

const isLast = ref(false);

const sliderOptions: KeenSliderOptions = {
	loop: false,
	mode: "snap",
	slides: { perView: "auto", spacing: props.spacing },
	breakpoints: {
		"(max-width: 767px)": {
			slides: {
				perView: props.isSection ? 3 : "auto",
				spacing: props.spacing
			}
		}
	},
	created(slider) {
		sliderActiveSlide.value = slider.track.details.abs;
	},
	slideChanged(slider) {
		sliderActiveSlide.value = slider.track.details.abs;
	},
	dragged(slider) {
		isLast.value = slider.track.details.slides[props.list.length - 1].portion >= 0.98;
	},
	detailsChanged(slider) {
		isLast.value = slider.track.details.slides[props.list.length - 1].portion >= 0.98;
	}
};

const sliderRef = ref();

const interval = ref();
onMounted(() => {
	if (!props.autoplay) {
		return;
	}
	interval.value = setInterval(() => {
		sliderRef.value.slider.prev();
	}, 2500);
});

onBeforeUnmount(() => {
	clearInterval(interval.value);
});

const { list } = toRefs(props);

watch(
	() => list.value,
	() => {
		nextTick(() => {
			sliderRef.value?.slider?.update();
		});
	}
);

const getDataTid = (index?: number) => {
	if (index === 0) {
		return `slider-first-item`;
	}
	return null;
};
</script>

<template>
	<div :class="['wrapper', { 'wrapper-with-section': isSection }]" :style="{ '--slider-offset': `${props.offset}px` }">
		<div class="header">
			<AText type="h8 h5-md" :data-tid="`slider-title-${getTitle(title)}`">
				{{ title }}
			</AText>
			<slot name="viewAll" />
		</div>
		<client-only>
			<ASlider ref="sliderRef" :options="sliderOptions">
				<div :class="['cards-feed__container', { 'no-padding': isLast }]">
					<div
						v-for="(item, index) in list"
						:key="index"
						class="cards-feed__item keen-slider__slide"
						:data-tid="getDataTid(index)"
					>
						<slot :item="item" :index="index" />
					</div>
				</div>
			</ASlider>
			<div v-if="navigation" class="nav-container">
				<div :data-tid="`slider-${getTitle(title)}-prev`" @click="sliderRef.slider?.prev()">
					<NuxtIcon
						:class="['swiper-button', { 'swiper-button-disabled': sliderActiveSlide === 0 }]"
						name="16/right-slider"
					/>
				</div>
				<div :data-tid="`slider-${getTitle(title)}-next`" @click="sliderRef.slider?.next()">
					<NuxtIcon :class="['swiper-button', { 'swiper-button-disabled': isLast }]" name="16/left-slider" />
				</div>
			</div>
		</client-only>
	</div>
</template>

<style lang="scss" scoped>
.wrapper {
	@include media-breakpoint-down(xl) {
		margin-right: calc(-1 * var(--slider-offset));
	}

	@include media-breakpoint-down(md) {
		margin-left: calc(-1 * var(--slider-offset));
	}

	&-with-section {
		@include media-breakpoint-down(md) {
			margin-right: 0;
			margin-left: 0;

			.cards-feed__container {
				padding-left: 0;
			}

			.header {
				padding-left: 0;
				padding-right: 0;
			}
		}
	}
}
.flip-list-move {
	transition: transform 0.8s ease;
}
.header {
	z-index: 20;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 16px;
	&-title {
		color: var(--neutral);
	}

	@include media-breakpoint-down(xl) {
		padding-right: var(--slider-offset);
	}

	@include media-breakpoint-down(md) {
		padding-left: var(--slider-offset);
	}
}

.cards-feed__container {
	@include media-breakpoint-down(md) {
		padding-left: var(--slider-offset);

		&.no-padding {
			padding-left: 0;
		}
	}
}

.cards-feed {
	$self: &;
	position: relative;
	z-index: 5;

	&__container {
		display: flex;
		position: relative;
	}
	&__item {
		height: auto;
		flex-shrink: 0;
		z-index: 3;
		position: relative;
		transform: translateX(0);
	}
}

.nav-container {
	display: flex;
	gap: 24px;
	margin-top: 16px;
	@include media-breakpoint-down(md) {
		display: none;
	}
	.swiper-button {
		color: var(--primary);
		cursor: pointer;

		&-disabled {
			opacity: 0.4;
		}
	}
}
</style>
